import {ApiResponse, ListResponse} from '@/models';
import { StreamSystemQueryModel } from '@/models/filter';
import {
    StreamSystemAssetItem,
    StreamSystemAssetSaveItem,
    StreamSystemItem, StreamSystemAddRequest, StreamSystemEditRequest, StreamSystemAddResponse, StreamSystemEditResponse
} from '@/models/stream-system-item';
import SdkGenericApiHelper from '@/utils/sdk-generic-api-helper';
import { generateQuery } from '@/utils/api-helper';

export class StreamSystemService {
    private api = new SdkGenericApiHelper('stream-systems');

    public async getAllStreamSystems(params: any = null): Promise<StreamSystemItem[]> {
        const query = generateQuery(params as any);
        const { data: { result: { items } } } = await this.api.get(query);
        return items;
    }

    public async getStreamSystemAssets(
        systemSid: number,
        assetTypeId: number,
        params: StreamSystemQueryModel,
    ): Promise<ApiResponse<ListResponse<StreamSystemAssetItem>>> {
        const query = generateQuery(params as any);
        return (await this.api.get(query, `${systemSid}/assets/${assetTypeId}`)).data;
    }

    public async saveStreamSystemAssets(
        systemSid: number,
        data: StreamSystemAssetSaveItem,
    ): Promise<ApiResponse<void>> {
        return (await this.api.post(data, `${systemSid}`)).data;
    }

    public async addStreamSystem(streamSystemAddRequest: StreamSystemAddRequest):
        Promise<ApiResponse<StreamSystemAddResponse>> {
        return (await this.api.post(streamSystemAddRequest))
            .data;
    }

    public async editStreamSystem(id: number, streamSystemEditRequest: StreamSystemEditRequest):
        Promise<ApiResponse<StreamSystemEditResponse>> {
        return (await this.api.put(`${id}`, streamSystemEditRequest))
            .data;
    }
}
