import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { UserProfile } from '@/models';
import PERMISSION_NAMES from '@/utils/permission-names';
import { sdk } from '@/utils/sdk';

@Module({ namespaced: true })
class UserModule extends VuexModule {
    public userProfile: UserProfile | null = null;

    get userProfileData(): UserProfile | null {
        return this.userProfile;
    }

    get userName(): string | undefined {
        return this.userProfile?.name;
    }

    get userEmail(): string | undefined {
        return this.userProfile?.email;
    }

    @Mutation
    public setUserProfileData(userProfile: UserProfile): void {
        this.userProfile = userProfile;
    }

    public get hasMasterDataView(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_ADMIN)
            || !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_VIEW);
    }

    public get hasMasterDataAdmin(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_ADMIN);
    }

    public get canExportExcel(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_EXCEL_EXPORT);
    }

    public get canViewStreamAssets(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_STREAM_ASSETS);
    }

    public get canViewSynergyLife(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_SYNERGY_LIFE_MAPPING);
    }

    public get canViewHydroComponents(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_HYDRO_READ);
    }

    public get canEditHydroComponents(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_HYDRO_WRITE);
    }

    public get canManageStreamSystems(): boolean {
        return !!this.userProfile?.permissions?.includes(PERMISSION_NAMES.MD_CAN_MANAGE_STREAM_SYSTEMS);
    }

    @Action
    public loadUserProfile(): void {
        const profile = sdk?.localStorage.getPermissionsLocalStorage()
        const permissionNames = profile.permissions?.map((permission: any) => permission.name);
        const userProfile = new UserProfile(profile?.name, profile?.email, permissionNames);
        this.context.commit('setUserProfileData', userProfile);
    }
}
export default UserModule;
