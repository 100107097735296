<template>
    <div class="stream-system-assets">
        <pui-headline type="h3" class="header-title">
            {{ $t('streamSystemAssets').toString() }}
        </pui-headline>
        <div class="stream-assets-body">
            <pui-grid-row>
                <pui-grid-column :cols="{s: 12, m:8, l:6, xl: 4, xxl: 4}">
                    <pebble-drop-down-select
                        name="selectExternalSystem"
                        :model="{}"
                        :label="$t('selectExternalSystem').toString()"
                        :options="systemAssetsDropdownList"
                        :required="true"
                        :is-valid="true"
                        :is-readonly="false"
                        :is-disabled="false"
                        :has-searchable-secondary-labels="true"
                        @on-input="systemSelected"
                    />
                </pui-grid-column>
                <pui-grid-column v-if="canManageStreamSystems" :cols="{s:8, m:6, l:4, xl:2, xxl: 1}" class="flex-row mt-3">
                    <div class="flex-row align-end">
                        <pui-button
                            v-if="selectedSystem"
                            class="ml-3"
                            state="secondary"
                            icon="edit"
                            @click="editExternalSystem"
                        >
                            {{ $t('edit') }}
                        </pui-button>
                        <pui-button
                            class="ml-3"
                            icon="add"
                            @click="addNewExternalSystem"
                        >
                            {{ $t('addNew') }}
                        </pui-button>
                    </div>
                </pui-grid-column>
                <stream-system-assets-add-edit
                    ref="streamSystemAssetsAddEdit"
                    @reload-stream-system="reloadStreamSystem"
                />
            </pui-grid-row>
            <div v-if="selectedSystem" class="flex-row stream-assets-lists mb-2">
                <div class="flex-col flex-1 mt-2 stream-assets-list">
                    <span class="mb-1 group-title">
                        {{ $t('availableAssets') }}
                    </span>
                    <div class="flex-row mb-1">
                        <search-component
                            :search-input-id="'availableStreamAssetSearch'"
                            :lazy-input-duration="1000"
                            @input="availableItemSearched">
                        </search-component>
                    </div>
                    <div class="assets-list available-assets-list mr-2">
                        <div
                            @dragover="allowDrop($event)"
                            @drop="drop($event, true)">
                            <div v-if="!availableAssetsFiltered || availableAssetsFiltered.length == 0" class="ml-2">
                                <span>{{ $t('noAssetsAvailable') }}</span>
                            </div>
                            <div v-else>
                                <vertical-list-transition tag="div">
                                    <div
                                        v-for="item of availableAssetsFiltered"
                                        :key="item.assetId"
                                        :value="item.id"
                                        draggable="true"
                                        @dragstart="dragItem($event, item)"
                                        @dblclick="manageItemsToGroup(item, false)">
                                        <stream-system-asset-card-list :card="item" />
                                    </div>
                                </vertical-list-transition>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-col flex-1 ml-1 mt-2 stream-assets-list">
                    <span class="mb-1 ml-1 group-title">
                        {{ $t('selectedAssets') }}
                    </span>
                    <div class="flex-row mb-1 ml-1">
                        <search-component
                            :search-input-id="'selectedStreamAssetSearch'"
                            :lazy-input-duration="1000"
                            @input="selectedItemSearched">
                        </search-component>
                    </div>
                    <div class="assets-list">
                        <div
                            @dragover="allowDrop($event)"
                            @drop="drop($event, false)">
                            <div v-if="!selectedAssetsFiltered || selectedAssetsFiltered.length == 0" class="ml-2">
                                <span>{{ $t('noAssetsSelected') }}</span>
                            </div>
                            <div v-else>
                                <vertical-list-transition tag="div">
                                    <div
                                        v-for="item of selectedAssetsFiltered"
                                        :key="item.assetId"
                                        :value="item.id"
                                        draggable="true"
                                        @dragstart="dragItem($event, item)"
                                        @dblclick="manageItemsToGroup(item, true)">
                                        <stream-system-asset-card-list :card="item" />
                                    </div>
                                </vertical-list-transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="selectedSystem" class="list-separator" />

            <div v-if="selectedSystem" class="flex-row full-width align-end">
                <pebble-text-field
                    id="enumName"
                    name="comment"
                    :model="{value: comment}"
                    :placeholder="$t('comment')"
                    :label="$t('comment').toString()"
                    :max-length="50"
                    class="comment-input-field"
                />
                <pui-button small @click="resetChanges()" class="reset-changes-button">
                    {{ $t('resetChanges') }}
                </pui-button>
                <pui-button state="secondary" @click="saveSelectedStreamSystems()">{{ $t('save') }}</pui-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import StreamSystemAssetCardList from '@/components/cards/cards-list/stream-system-asset-card-list/stream-system-asset-card-list.vue';
import SearchComponent from '@/components/search-component/search-component.vue';
import VerticalListTransition from '@/components/transitions/vertical-list-transition/vertical-list-transition.vue';
import { StreamSystemQueryModel } from '@/models/filter';
import { StreamSystemAssetItem, StreamSystemAssetSaveItem, StreamSystemItem } from '@/models/stream-system-item';
import { StreamSystemService } from '@/services';
import { EventBus } from '@/utils';
import { Component, Mixins } from 'vue-property-decorator';
import PebbleDropDownSelect from '@/components/pebble-form-element/pebble-drop-down-select.vue';
import PebbleTextField from '@/components/pebble-form-element/pebble-text-field.vue';
import StreamSystemAssetsAddEdit from '@/components/view/stream-system-assets-add-edit.vue';
import ComponentSecurity from '@/mixins/component-security';

@Component ({
    name: 'stream-system-assets',
    components: {
        searchComponent: SearchComponent,
        verticalListTransition: VerticalListTransition,
        streamSystemAssetCardList: StreamSystemAssetCardList,
        streamSystemAssetsAddEdit: StreamSystemAssetsAddEdit,
        pebbleDropDownSelect: PebbleDropDownSelect,
        pebbleTextField: PebbleTextField,
    },
})
export default class StreamSystemAssets extends Mixins(ComponentSecurity) {

    private systemAssetsDropdownList: Array<{
        label: string;
        secondaryLabel: string;
        value: number;
    }> = [];
    private systemAssetList: StreamSystemItem[] = [];
    private selectedSystem: StreamSystemItem | null = null;
    private availableAssetsFiltered: StreamSystemAssetItem[] | null = null;
    private selectedAssetsFiltered: StreamSystemAssetItem[] | null = null;
    private selectedAssets: StreamSystemAssetItem[] | null = null;
    private availableAssets: StreamSystemAssetItem[] | null = null;
    private originalSelectedAssets: StreamSystemAssetItem[] | null = null;
    private originalAvailableAssets: StreamSystemAssetItem[] | null = null;
    private searchTermAvailableAsset = '';
    private searchTermSelectedAsset = '';
    private comment = '';

    private mounted(): void {
        this.getAllStreamSystems();
    }

    private async getAllStreamSystems() {
        this.systemAssetList = await new StreamSystemService().getAllStreamSystems();
        this.systemAssetsDropdownList = this.systemAssetList.map((o) => {
            return {
                label: `${o.streamSystemSid} - ${o.name}`,
                secondaryLabel: o.description,
                value: o.streamSystemSid,
            };
        });
    }

    private async reloadStreamSystem() {
        this.selectedSystem = null;
        this.systemAssetList = [];
        this.systemAssetsDropdownList = [];
        this.clearSelectedList();
        await this.getAllStreamSystems();
    }

    private clearSelectedList(): void {
        this.originalSelectedAssets = null;
        this.originalAvailableAssets = null;
        this.selectedAssets = null;
        this.availableAssets = null;
        this.filterSelectedItems();
        this.filterAvailableItems();
    }

    private async systemSelected(selectedValue: number) {
        this.selectedSystem = null;
        this.clearSelectedList();
        const filter: StreamSystemQueryModel = {
            page: 1,
            size: 9999,
            term: '',
            selection: 0,
        };
        this.selectedSystem = this.systemAssetList.find((o) => o.streamSystemSid === selectedValue) ?? null;
        if (this.selectedSystem) {
            try {
                filter.selection = 1;
                const selectedOnly = (await this.getStreamSystemAsset(
                    this.selectedSystem.streamSystemSid,
                    this.selectedSystem.assetPermissionTypeLevel,
                    filter,
                )).result.items;
                this.originalSelectedAssets = selectedOnly.slice();
                this.selectedAssets = selectedOnly;
                this.filterSelectedItems();
            } catch {
                this.selectedAssets = null;
            }
            try {
                filter.selection = 2;
                const availableOnly = (await this.getStreamSystemAsset(
                    this.selectedSystem.streamSystemSid,
                    this.selectedSystem.assetPermissionTypeLevel,
                    filter,
                )).result.items;
                this.originalAvailableAssets = availableOnly.slice();
                this.availableAssets = availableOnly;
                this.filterAvailableItems();
            } catch {
                this.availableAssets = null;
            }
        }
    }

    private addToAssetList(item: StreamSystemAssetItem, assetList: StreamSystemAssetItem[] | null) {
        assetList?.push(item);
    }

    private removeFromAssetList(item: StreamSystemAssetItem, assetList: StreamSystemAssetItem[] | null) {
        const itemIndex = assetList?.indexOf(item) ?? -1;
        if (itemIndex > -1) {
            assetList?.splice(itemIndex, 1);
        }
    }

    private dragItem($event: any, item: any): void {
        $event.dataTransfer.setData('item', JSON.stringify(item));
    }

    private allowDrop($event: any): void {
        $event.preventDefault();
    }

    private drop($event: any, firstArray: boolean): void {
        $event.preventDefault();
        const item = JSON.parse($event.dataTransfer.getData('item'));
        const array = firstArray ? this.selectedAssets : this.availableAssets;
        const itemInList = array?.find((r: StreamSystemAssetItem) => r.assetId === item.assetId);
        if (itemInList) {
            this.manageItemsToGroup(itemInList, firstArray);
        }
    }

    private availableItemSearched(term: string): void {
        this.searchTermAvailableAsset = term.trim().toLowerCase();
        this.filterAvailableItems();
    }

    private selectedItemSearched(term: string): void {
        this.searchTermSelectedAsset = term.trim().toLowerCase();
        this.filterSelectedItems();
    }

    private filterAvailableItems(): void {
        this.availableAssetsFiltered =
            this.availableAssets?.filter(
                (asset) => asset.assetName.toLowerCase().includes(this.searchTermAvailableAsset) ||
                    asset.assetId.toString().toLowerCase().includes(this.searchTermAvailableAsset),
            ) ?? null;
    }

    private filterSelectedItems(): void {
        this.selectedAssetsFiltered =
            this.selectedAssets?.filter(
                (asset) => asset.assetName.toLowerCase().includes(this.searchTermSelectedAsset) ||
                    asset.assetId.toString().toLowerCase().includes(this.searchTermSelectedAsset),
            ) ?? null;
    }

    private manageItemsToGroup(item: StreamSystemAssetItem, shouldRemoveItem: boolean): void {
        if (shouldRemoveItem) {
            this.removeFromAssetList(item, this.selectedAssets);
            this.addToAssetList(item, this.availableAssets);
        } else {
            this.removeFromAssetList(item, this.availableAssets);
            this.addToAssetList(item, this.selectedAssets);
        }
        this.filterSelectedItems();
        this.filterAvailableItems();
    }

    private async getStreamSystemAsset(
        systemSid: number,
        assetTypeId: number,
        params: StreamSystemQueryModel,
    ) {
        return await new StreamSystemService().getStreamSystemAssets(systemSid, assetTypeId, params);
    }

    private async saveSelectedStreamSystems() {
        if (this.selectedSystem) {
            try {
                this.$store.commit('loading');
                const saveObj: StreamSystemAssetSaveItem = {
                    enumStreamSystemSid: this.selectedSystem?.streamSystemSid,
                    lastUpdateComment: this.comment,
                    assets: this.selectedAssets ? this.selectedAssets.map((o) => {
                        return {
                            assetId: o.assetId,
                            assetType: o.assetType,
                            useCaseId: this.selectedSystem ? this.selectedSystem?.useCaseId : NaN,
                        };
                    }) : [],
                };
                const result = await new StreamSystemService()
                    .saveStreamSystemAssets(this.selectedSystem?.streamSystemSid, saveObj);
                if (result.statusCode === 200) {
                    this.comment = '';
                    await this.systemSelected(this.selectedSystem.streamSystemSid);
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'savedChanges');
                } else {
                    EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingAsset');
                }
            } catch {
                EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorEditingAsset');
            } finally {
                this.$store.commit('loading');
            }
        }
    }

    private resetChanges(): void {
        this.selectedAssets = this.originalSelectedAssets ? this.originalSelectedAssets.slice() : null;
        this.availableAssets = this.originalAvailableAssets ? this.originalAvailableAssets.slice() : null;
        this.filterSelectedItems();
        this.filterAvailableItems();
    }

    private addNewExternalSystem(): void {
        const newStreamSystem = {};
        (this.$refs?.streamSystemAssetsAddEdit as StreamSystemAssetsAddEdit)?.open(newStreamSystem);
    }

    private editExternalSystem(): void {
        const selectedStreamSystem = {
            id: this.selectedSystem?.streamSystemSid,
            name: this.selectedSystem?.name,
            description: this.selectedSystem?.description,
            assetPermissionType: this.selectedSystem?.assetPermissionTypeLevel,
            useCaseId: this.selectedSystem?.useCaseId,
        };
        (this.$refs?.streamSystemAssetsAddEdit as StreamSystemAssetsAddEdit)?.open(selectedStreamSystem, true);
    }
}
</script>

<style scoped lang="less">
@import '~@/variables.less';

.stream-system-assets {
    width: 100%;
    height: inherit;
    padding: 32px;
    overflow: hidden;

    .header-title {
        margin-bottom: 32px;
    }

    .stream-assets-body {
        height: calc(100% - 64px);
        background: white;
        padding: 0 32px 24px 32px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .stream-assets-lists {
        flex-grow: 1;
        height: calc(100% - 194px);

        .stream-assets-list {
            height: 100%;
        }

        .group-title {
            font-size: 2rem;
        }

        .assets-list {
            min-height: 28rem;
            height: inherit;
            overflow: hidden;
            overflow-y: auto;

            &.available-assets-list {
                margin-left: -1rem;
            }
        }
    }

    .list-separator {
        width: 100%;
        border-bottom: 1px solid @warm-grey-25;
        margin-top: 1.5rem;
        margin-bottom: -1.5rem;
    }

    .reset-changes-button {
        margin-bottom: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .comment-input-field {
        flex-grow: 1;
    }
}
</style>
