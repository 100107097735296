import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const userModule = namespace('UserModule');

@Component({})
export default class ComponentSecurity extends Vue {
    @userModule.Getter
    public hasMasterDataView!: boolean;

    @userModule.Getter
    public hasMasterDataAdmin!: boolean;

    @userModule.Getter
    public canViewStreamAssets!: boolean;

    @userModule.Getter
    public hasSynergyLifeView!: boolean;

    @userModule.Getter
    public canViewSynergyLife!: boolean;

    @userModule.Getter
    public canViewHydroComponents!: boolean;

    @userModule.Getter
    public canEditHydroComponents!: boolean;

    @userModule.Getter
    public canManageStreamSystems!: boolean;
}
